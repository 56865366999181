import "./index.scss";
import "../../../App.scss";
import { Link } from "react-router-dom";
import { useLocationParams, fetchImage } from "../../nonUIFuncs";
import ProgressBar from "../ProgressBar";
import PropTypes from 'prop-types';
import { selectReduxSlice } from "../../../Store/store";
import { useSelector } from 'react-redux';

const IndivGame = ({
  name,
  progress,
  stars,
  image,
  paramToSet,
  classNamePre = "indivgame",
  onmouseover,
  path,
  onClick,
  game,
  setKey,
}) => {
  const reduxState = useSelector(selectReduxSlice);
  let titlePositioning = {
    left: '16%',
    right: '3%',
  }
  if (classNamePre === "unitsubmenu") {
    titlePositioning.left = "8%";
  }
  const adjustFontSize = (denom, low, high) => {
    let adjustedFontSize = reduxState.width / denom / name.length;
    if (adjustedFontSize < low) {
      adjustedFontSize = low;
    } else if (adjustedFontSize > high) {
      adjustedFontSize = high;
    }
    return adjustedFontSize;
  };
  let keyParam = "";
  if (setKey) {
    keyParam = `&Key=${game.Key}`;
  }
  return (
    <Link
      onMouseOver={onmouseover}
      to={{
        pathname: path,
        search: useLocationParams(`${paramToSet}=${name}${keyParam}`, []),
      }}
      onClick={onClick}
      className={`${classNamePre}_container hovercursor`}
    >
      <div className={`${classNamePre}_innercontainer`}>
        <div className={`${classNamePre}_badge`}>
          <div
            className={`${classNamePre}_gameimg`}
            style={{ backgroundImage: `url(${fetchImage(image)})` }}
          />
          <div className={`${classNamePre}_badgebanner`}>
            <div
              className={`${classNamePre}_${stars >= 1 ? "Star" : "Star_Base"}`}
            />
            <div
              className={`${classNamePre}_${stars >= 2 ? "Star" : "Star_Base"}`}
            />
            <div
              className={`${classNamePre}_${stars >= 3 ? "Star" : "Star_Base"}`}
            />
          </div>
        </div>
        <div className={`${classNamePre}_titlecontainer`}>
          <div
            style={{
              ...titlePositioning,
              position: "absolute",
              // backgroundColor: 'rgba(255,0,0,0.6)',
              display: "flex",
              justifyContent: "center",
            }}>
            <h2
              style={{
                fontSize:
                  classNamePre === "unitsubmenu"
                    ? adjustFontSize(6.7, 9, 25)
                    : adjustFontSize(4.7, 16, 48),
              }}
              className={`${classNamePre}_title`}>
              {name}
            </h2>
          </div>
          <div className={`${classNamePre}_progressbarcontainer`}>
            <ProgressBar
              width="clamp(37px, 7.5vw, 98px)"
              id={`${name}_IndivGame`}
              progress={progress}
            />
          </div>
        </div>
      </div>
    </Link>
  );
};

export default IndivGame;

IndivGame.propTypes = {
  name: PropTypes.string,
  progress: PropTypes.string,
  stars: PropTypes.number,
  image: PropTypes.string,
  paramToSet: PropTypes.string,
  classNamePre: PropTypes.string,
  onmouseover: PropTypes.func,
  path: PropTypes.string,
  onClick: PropTypes.func,
};