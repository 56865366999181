import "./index.scss";
import "../../App.scss";
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Matter from "matter-js";

import RoundBanner from "../UI/RoundBanner";
import CloseEndless from "../UI/CloseEndless";
import EndEndlessGame from "../UI/EndEndlessGame";
import { fetchAudio, fetchImage, getSearchParams, capEveryWord } from "../nonUIFuncs";
import PopupWord from "../UI/PopupWord";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectReduxSlice, setStats, setEndlessLevel, setEndlessLevelProgress } from "../../Store/store";

const Engine = Matter.Engine;
const Render = Matter.Render;
const Runner = Matter.Runner;
const Composite = Matter.Composite;
const Bodies = Matter.Bodies;
const Mouse = Matter.Mouse;
const MouseConstraint = Matter.MouseConstraint;
let engineT = Engine.create();
let render;

let difficultyTimeout = null;
let canvasToScreenDifWidth = 1;
let canvasToScreenDifHeight = 1;
let canvasHeight;
let canvasWidth;
let showPopup = false;
let correctSoundEffect = new Audio(fetchAudio("correctSound.mp3"));
let iLike;
let iDontLike;
let smallerDim = null;
let chosenItem = { English: null };
let fullRoundData = [];
let audioElement = undefined;
let targetBoxSize = 1;
let targetScale = 1;
let itemScale;
let shortCounter = 0;
let interval;
let dragStart;
let targetImage = null;
let talkCloud = `url(${fetchImage("talkCloud.png")})`;
let round = 1;
let items = [];
let difficulty = "easy";

const SwitchDisplay = (props) => {
  props.stopAudio();
  props.clearPopupTimeout();
  return (
    <div className="IFG-statScreenContainer">
      <RoundBanner
        round={round + 1}
        starsObj={props.starsObj}
        findStars={props.findStars}
      />
    </div>
  );
};

const ItemFall = (props) => {
  let popupTimeout;
  let items;
  const Location = useLocation();
  const Params = getSearchParams(Location);
  const dispatch = useDispatch();
  const reduxState = useSelector(selectReduxSlice);
  const canvasRef = useRef();
  const currentTimerRef = useRef();
  const reduxStateRef = useRef();
  const propItemsRef = useRef();
  const [talking, setTalking] = useState(true);
  const [body, setBody] = useState(false);
  const [showEndlessEnd, setShowEndlessEnd] = useState(false);
  const [chosenPopupWord, setChosenPopupWord] = useState(null);
  const [currentTimer, setCurrentTimer] = useState(0);
  const [display, setDisplay] = useState("game");
  const [propItems, setPropItems] = useState([]);
  currentTimerRef.current = currentTimer;
  reduxStateRef.current = reduxState;
  propItemsRef.current = propItems;
  useEffect(() => {
    if (!Location.pathname.includes("/endless-mode/")) {
      let string = `${Params.find((d) => d.id === "game-type").value} - ${
        Params.find((d) => d.id === "game-name").value
      } - ${reduxStateRef.current.difficulty}`;
      document.title = capEveryWord(string);
      difficulty = reduxState.difficulty;
    } else {
      difficulty = Params.find(
        (d) => d.id === "difficulty"
      ).value.toLowerCase();
    }
    iLike = new Audio(`${fetchAudio(reduxState.specificGame.positiveAudio)}`);
    iDontLike = new Audio(`${fetchAudio(reduxState.specificGame.negativeAudio)}`);
    setDisplay("game");
    dispatch(setEndlessLevel(0));
    dispatch(setEndlessLevelProgress(0));
    shortCounter = 0;
    if (reduxStateRef.current.width > reduxStateRef.current.height) {
      smallerDim = reduxStateRef.current.height;
    } else {
      smallerDim = reduxStateRef.current.width;
    }
    targetBoxSize = smallerDim / 4;
    let possibleTargetData = reduxStateRef.current.gameData.filter(
      (d) => d.subCat === reduxStateRef.current.specificGame.items // TODO: Text if the data for target and items are flipped
    );
    let randTarget = possibleTargetData[Math.floor(Math.random() * possibleTargetData.length)];
    let targetImageSizes = new Image();
    targetImageSizes.src = `${fetchImage(randTarget.App_Art)}`;
    targetImageSizes.onload = (targetImageSizes) => {
      let targetW = getImageSize(targetImageSizes).width;
      let targetH = getImageSize(targetImageSizes).height;

      if (targetH >= targetW) {
        itemScale = ((smallerDim / 6) * 1.1) / targetH;
        targetScale = ((smallerDim / 4) * 1.1) / targetH;
      } else {
        itemScale = ((smallerDim / 6) * 1.1) / targetW;
        targetScale = ((smallerDim / 4) * 1.1) / targetW;
      }
    };
    targetImage = `${fetchImage(randTarget.App_Art)}`;
    getGameDataAndAudio();
    createWorld();
    return () => {
      stopAudio();
      engineT.world.bodies = [];
      engineT.world.bodies.forEach((body) => {
        if (body.isStatic === false) {
          Composite.remove(engineT.world, [body]);
        }
      });
      chosenItem = { English: null };
      clearTimeout(popupTimeout);
      clearTimeout(difficultyTimeout);
      clearTimeout(items);
    };
  }, []);
  const createWorld = () => {
    // engineT = Engine.create();
    let backgroundImage = new Image();
    backgroundImage.src = `${fetchImage("welcome_background.png")}`;
    backgroundImage.onload = (backgroundImage) => {
      createCanvas(backgroundImage);
    }
    engineT.gravity = {
      x: 0,
      y: 0.08,
    };
  };
  const getImageSize = (image) => {
    return {
      height: image.target.naturalHeight,
      width: image.target.naturalWidth,
      ratio: image.target.naturalHeight / image.target.naturalWidth,
      revRatio: image.target.naturalWidth / image.target.naturalHeight,
    };
  };
  const createCanvas = (backgroundImage) => {
    // 1. Set the maximum size of the play area based on height and width of screen to game background
    let backgroundRatio = getImageSize(backgroundImage).ratio;
    let backgroundRevRatio = getImageSize(backgroundImage).revRatio;
    if (
      reduxStateRef.current.width * backgroundRatio <
      reduxStateRef.current.height
    ) {
      canvasHeight = reduxStateRef.current.width * backgroundRatio;
      canvasWidth = reduxStateRef.current.width;
    } else {
      canvasHeight = reduxStateRef.current.height;
      canvasWidth = reduxStateRef.current.height * backgroundRevRatio;
    }
    canvasToScreenDifWidth = reduxStateRef.current.width - canvasWidth;
    canvasToScreenDifHeight = reduxStateRef.current.height - canvasHeight;
    // 2. Create the canvas
    render = Render.create({
      canvas: canvasRef.current,
      engine: engineT,
      options: {
        hasBounds: true,
        background: `url(${fetchImage("welcome_background.png")})`,
        height: canvasHeight,
        width: canvasWidth,
        wireframes: false,
      },
    });

    // 3. Create the mouse and add it to the World
    let mouse = Mouse.create(render.canvas);
    let mouseConstraint = MouseConstraint.create(engineT, {
      mouse: mouse,
      constraint: {
        stiffness: 0.2,
        render: {
          visible: false,
        },
      },
    });
    Composite.add(engineT.world, [mouseConstraint]);
    // 4. create the collisionStart event
    Matter.Events.on(engineT, "collisionStart", ({ pairs }) => {
      pairs.forEach(({ bodyA, bodyB }) => {
        if (bodyA.render.sprite.texture === targetImage) {
          let image = bodyB.render.sprite.texture.match(/(?:.+\/)(.+)/);
          let audioToPlay = propItemsRef.current.find((d) => d.App_Art === image[1]);
          resetAudioElement(audioToPlay);
          if (image[1] === chosenItem.App_Art) {
            upScore();
            Composite.remove(engineT.world, [bodyB]);
            if (chosenItem.App_Audio.length > 0) {
              setTimeout(() => audioWL(), 1);
            }
          } else {
            downScore();
            bodyB.position.x = bodyB.position.x - spawnWidth(10, 15);
            bodyB.position.y = bodyB.position.y - spawnWidth(10, 15);
            setTimeout(() => audioWDL(bodyB), 1);
          }
        }
      });
    });
    // 5. create the mousedown event
    Matter.Events.on(mouseConstraint, "mousedown", function () {
      dragStart = Date.now();
      if (mouseConstraint.body !== null) {
        let image = mouseConstraint.body.render.sprite.texture.match(/(?:.+\/)(.+)/);
        let audioToPlay = propItemsRef.current.find((d) => d.App_Art === image[1]);
        stopAudio();
        if (audioToPlay !== undefined) {
          resetAudioElement(audioToPlay);
        } else {
          resetAudioElement(chosenItem);
        }
        
        if (mouseConstraint.body.isStatic === true) {
          setTimeout(() => audioWL(), 1);
        } else {
          setBody(mouseConstraint.body);
          // mouseStartX = mouseConstraint.mouse.position.x;
          // mouseStartY = mouseConstraint.mouse.position.y;
          setTimeout(() => audioElement.play(), 1);
        }
      }
    });
    Matter.Events.on(mouseConstraint, "mouseup", function () {
      if (body !== false && Date.now() - dragStart < 250) {
        // let xDif = mouseConstraint.mouse.position.x - mouseStartX;
        // let yDif = mouseConstraint.mouse.position.y - mouseStartY;
        // let timeDif = Date.now() - dragStart;
        // console.log(xDif / timeDif, yDif / timeDif);

        // This statement should be more accurate to apply the correct force, but the 0s look a little less buggy.
        // Matter.Body.applyForce(body, mouseConstraint.mouse.position, {x: xDif/timeDif, y: yDif/timeDif});

        Matter.Body.applyForce(body, mouseConstraint.mouse.position, {
          x: 0,
          y: 0,
        });
      }
      setBody(false);
    });
    // 6. Now that everything needed for the canvas has been created and added, run it
    Render.run(render);
    let runner = Runner.create();
    Runner.run(runner, engineT);
    setTarget();
  };
  const setTarget = () => {
    const gameTarget = Bodies.rectangle(
      canvasWidth - targetBoxSize / 2,
      canvasHeight - targetBoxSize / 2,
      targetBoxSize,
      targetBoxSize,
      {
        isStatic: true,
        collisionFilter: {
          group: 1,
        },
        render: {
          sprite: {
            texture: targetImage,
            xScale: targetScale,
            yScale: targetScale,
          },
        },
      }
    );
    Composite.add(engineT.world, [gameTarget]);
  };
  const getGameDataAndAudio = () => {
    let foundItems = reduxStateRef.current.gameData.filter(
      (d) => d.subCat === reduxStateRef.current.specificGame.target // TODO: Test if the data for target and items are flipped
    );
    setPropItems(foundItems);
    setRoundData(foundItems);
  };
  const spawnWidth = (min, max) => {
    return Math.floor(Math.random() * Math.floor(max - min + 1) + min);
  };
  const spawnItem = (timerNumber) => {
    if (currentTimerRef.current !== timerNumber) {
      clearInterval(timerNumber);
      return;
    }
    let sprite = `${fetchImage(
      fullRoundData[Math.floor(Math.random() * fullRoundData.length)].App_Art
    )}`;

    let itemBody = Bodies.rectangle(
      spawnWidth(targetBoxSize / 2, canvasWidth - targetBoxSize / 2),
      -targetBoxSize * 0.8,
      targetBoxSize * 0.8,
      targetBoxSize * 0.8,
      {
        isStatic: false,
        restitution: 0.7,
        render: {
          sprite: {
            texture: sprite,
            xScale: itemScale * 0.9,
            yScale: itemScale * 0.9,
          },
        },
      }
    );

    Composite.add(engineT.world, [itemBody]);


    engineT.world.bodies.forEach((body) => {
      if (body.isStatic === false) {
        if (
          body.position.y >
          reduxStateRef.current.height + targetBoxSize * 0.75
        ) {
          Composite.remove(engineT.world, [body]);
        } else if (body.position.x < -targetBoxSize) {
          Composite.remove(engineT.world, [body]);
        } else if (
          body.position.x >
          reduxStateRef.current.width + targetBoxSize
        ) {
          Composite.remove(engineT.world, [body]);
        }
      }
    });
  };
  const setRoundData = (itemData) => {
    clearInterval(items);
    shortCounter = 0;
    fullRoundData = [];
    let optionsArr = itemData.filter(
      (item) => item.English !== chosenItem.English
    );
    chosenItem = optionsArr[Math.floor(Math.random() * optionsArr.length)];
    resetAudioElement(chosenItem);
    setTalking(true);
    // Push the first one here
    fullRoundData.push(chosenItem);
    let i = 0;
    let distNumber;
    if (difficulty === "easy") {
      interval = 4000;
      distNumber = 1;
      engineT.gravity = {
        x: 0,
        y: 0.06,
      };
    } else if (difficulty === "medium") {
      interval = 2750;
      distNumber = 2;
      engineT.gravity = {
        x: 0,
        y: 0.07,
      };
    } else if (difficulty === "hard") {
      interval = 2000;
      distNumber = 3;
      engineT.gravity = {
        x: 0,
        y: 0.08,
      };
    }
    let distractors = itemData.filter(
      (d) => d.English !== chosenItem.English
    );
    let randomDistractor;
    while (i < distNumber) {
      i++;
      randomDistractor = distractors[Math.floor(Math.random() * distractors.length)];
      distractors = distractors.filter((d) => d.English !== randomDistractor.English);
      // In order to get more of the correct answer we are going to push the
      // chosenItem one more time than we have for the number of distractors.
      // Depending on dif, the chosenItem will have from a 57 - 66% chance to spawn.
      fullRoundData.push(chosenItem);
      fullRoundData.push(randomDistractor);
    }
    if (chosenItem.App_Audio.length > 0) {
      setTimeout(() => audioWL(), 1);
    }
    clearInterval(items);
    items = setInterval(() => spawnItem(items), interval); // TODO: Put Back
    setCurrentTimer(items);
    setTimeout(() => {
      setTalking(false);
    }, 4000);
  };
  const stopAudio = () => {
    let audioArray = [iLike, iDontLike, audioElement];
    audioArray.forEach((a) => {
      if (a !== undefined) {
        a.pause();
        a.currentTime = 0;
      }
    });
  };
  const audioWL = () => {
    iLike.play();
    iLike.onended = () => {
      audioElement.play();
    };
  };
  const audioWDL = () => {
    iDontLike.play();
    iDontLike.onended = () => {
      audioElement.play();
    };
  };
  const resetAudioElement = (mouseBody) => {
    if (audioElement !== undefined) {
      audioElement.pause();
      audioElement.currentTime = 0;
      // audioElement = undefined;
    }
    audioElement = new Audio(`${fetchAudio(mouseBody.App_Audio)}`);
    // audioElement.play();
  };
  const nextEndlessLevel = () => {
    dispatch(setEndlessLevel(reduxStateRef.current.currentLevel + 1));
    dispatch(setEndlessLevelProgress(0));
    let number = document.getElementById("levelNumber");
    number?.classList.add("levelNumber");
    setTimeout(() => number?.classList.remove("levelNumber"), 1200);
    setTimeout(() => props.setProgress("0%"), 700);
  };
  const upScore = () => {
    let isEndless = Location.pathname.includes("/endless-mode/");
    if (isEndless === false) {
      // let progressBarVal = Math.round(
      //   ((imageIndex.length + 1) / (imageDOMArray.length + imageIndex.length)) *
      //     100
      // );
      // props.setProgress(`${progressBarVal}%`);
    } else {
      let current = reduxStateRef.current.currentLevelProgress + 1;
      let denom = reduxStateRef.current.currentLevel + 2;
      let progressBarVal = Math.round((current / denom) * 100);
      props.setProgress(`${progressBarVal}%`);
    }
    stopAudio();
    let popupWords = reduxState.popupWords.filter(
      (datum) => datum.type === "correct"
    );
    showPopup = true;
    popupTimeout = setTimeout(() => (showPopup = false), 1300);

    correctSoundEffect.play();
    setChosenPopupWord(
      popupWords[Math.floor(Math.random() * popupWords.length)].Language
    );
    dispatch(
      setStats({
        hits: reduxStateRef.current.stats.hits + 1,
        score: reduxStateRef.current.stats.score + 1,
        roundStats: {
          hits: reduxStateRef.current.stats.roundStats.hits + 1,
          score: reduxStateRef.current.stats.roundStats.score + 1,
        },
      })
    );
    let progress = reduxStateRef.current.currentLevelProgress + 1;
    dispatch(setEndlessLevelProgress(progress));
    if (
      reduxStateRef.current.currentLevel + 2 === progress &&
      isEndless === true
    ) {
      setTimeout(nextEndlessLevel, 700);
    }
    shortCounter = shortCounter + 1;
  };
  const downScore = () => {
    let isEndless = Location.pathname.includes("/endless-mode/");
    if (isEndless && reduxStateRef.current.currentLevelProgress > 0) {
      let current = reduxStateRef.current.currentLevelProgress - 1;
      let denom = reduxStateRef.current.currentLevel + 2;
      let progressBarVal = Math.round((current / denom) * 100);
      props.setProgress(`${progressBarVal}%`);
    }
    stopAudio();
    let popupWords = reduxState.popupWords.filter(
      (datum) => datum.type === "incorrect"
    );
    showPopup = true;
    popupTimeout = setTimeout(() => {
      showPopup = false;
      setChosenPopupWord(
        popupWords[Math.floor(Math.random() * popupWords.length)].Language
      );
    }, 1300);
    if (reduxStateRef.current.currentLevelProgress > 0) {
      dispatch(
        setEndlessLevelProgress(reduxStateRef.current.currentLevelProgress - 1)
      );
    }
    dispatch(
      setStats({
        misses: reduxStateRef.current.stats.misses + 1,
        score: reduxStateRef.current.stats.score - 1,
        roundStats: {
          misses: reduxStateRef.current.stats.roundStats.misses + 1,
          score: reduxStateRef.current.stats.roundStats.score - 1,
        },
      })
    );
    shortCounter = shortCounter - 1;
  };
  const stepDifficulty = () => {
    clearInterval(items);
    shortCounter = 0;
    difficultyTimeout = null;
    setDisplay("game");
    props.setProgress("0%");
    // ready = false;
    let bodies = engineT.world.bodies.filter((b) => b.isStatic !== true);
    Composite.remove(engineT.world, bodies, true);
    if (round < 3) {
      round = round + 1;
      setRoundData(propItemsRef.current);
      setTimeout(() => audioWL(), 1);
      setCurrentTimer(items);
    } else {
      props.checkWorld();
    }
    dispatch(setStats({ roundStats: { hits: 0, misses: 0, score: 0 } }));
  };
  if (
    reduxStateRef.current.stats.roundStats.score >= 10 &&
    Location.pathname.includes("/endless-mode/")
  ) {
    clearInterval(items);
    clearInterval(currentTimerRef.current);
    let bodies = engineT.world.bodies.filter((b) => b.isStatic !== true);
    Composite.remove(engineT.world, bodies, true);
    if (display !== "stats") setDisplay("stats");
    if (difficultyTimeout === null) {
      difficultyTimeout = setTimeout(stepDifficulty, 4000);
    }
  }
  let talkBubblePosition = {
    backgroundImage: talkCloud,
    bottom: 50,
    left: 50,
  };
  if (talking) {
    talkBubblePosition.bottom =
      targetBoxSize * 0.66 + canvasToScreenDifHeight / 2;
    talkBubblePosition.right = targetBoxSize + canvasToScreenDifWidth / 2;
    talkBubblePosition.left = "auto";
  }
  // After 5 correct answers change the chosenItem and distractors.
  if (
    shortCounter >= 5 &&
    reduxStateRef.current.stats.roundStats.score !== 10
  ) {
    console.log('this one')
    setTimeout(() => setRoundData(propItemsRef.current), 200);
  }
  // if (score >= 20 && routeLocation.state.endless !== true) {
  //   setDisplay('stats');
  // }

  return (
    <div className="itemFallGame-fullscreen">
      <canvas ref={(r) => (canvasRef.current = r)} />
      <div>
        {talking ? (
          <div className="noselect noClick IFG-greyBox">
            <div
              style={talkBubblePosition}
              className="noselect itemFallGame-keywordBox"
            >
              {reduxState.specificGame.positiveLanguage} {chosenItem.Language}
            </div>
          </div>
        ) : (
          <div
            onClick={() => audioWL()}
            style={talkBubblePosition}
            className="noselect itemFallGame-keywordBox"
          >
            {chosenItem.Language}
          </div>
        )}
        <PopupWord popupWord={chosenPopupWord} enabled={showPopup} />
      </div>
      {display === "stats" ? (
        <SwitchDisplay
          clearPopupTimeout={() => clearTimeout(popupTimeout)}
          stopAudio={stopAudio}
          starsObj={props.starsObj}
          findStars={props.findStars}
        />
      ) : null}
      {Location.pathname.includes("/endless-mode/") ? (
        <div
          style={{
            position: "absolute",
            zIndex: 99999999999999999999,
            width: "100%",
            height: "100%",
            pointerEvents: "none",
          }}
        >
          <div
            style={{
              position: "relative",
              top: "5%",
              right: "10%",
              pointerEvents: "all",
            }}
          >
            <CloseEndless
              enabled={Location.pathname.includes("/endless-mode/")}
              onClick={setShowEndlessEnd}
            />
          </div>
          <div style={{pointerEvents: 'all'}}>
            <EndEndlessGame
              enabled={showEndlessEnd}
              currentLevel={reduxStateRef.current.currentLevel}
              setShowEndlessEnd={setShowEndlessEnd}
              updateLevelData={props.updateLevelData}
              endGame={undefined}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ItemFall;

ItemFall.propTypes = {
  backgroundRatio: PropTypes.number,
  backgroundRevRatio: PropTypes.number,
  checkWorld: PropTypes.func,
  items: PropTypes.array,
  setProgress: PropTypes.func,
  target: PropTypes.object,
  targetH: PropTypes.number,
  targetW: PropTypes.number,
  updateLevelData: PropTypes.func,
};
SwitchDisplay.propTypes = {
  clearPopupTimeout: PropTypes.func,
  stopAudio: PropTypes.func,
};
