import "./index.scss";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  setEndlessCategories,
  selectReduxSlice
} from "../../Store/store";
import { useDispatch, useSelector } from "react-redux";

import LoadingScreen from "../LoadingScreen/index";
import GameSelect from "./GameSelect";
import CategorySelect from "./CategorySelect";
import DifficultySelect from "./DifficultySelect";

// Rather than supplying a lose condition and therefore ending this 'Endless Mode' concept,
// do not end the game, and don't allow currentLevelProgress to dip below 0.

let listOfGames = [];
let listOfCategories = [];

const EndlessModeMenu = (props) => {
  const dispatch = useDispatch();
  const reduxState = useSelector(selectReduxSlice);
  const [game, setGame] = useState(false);
  const [categories, setCategories] = useState([]);
  const [display, setDisplay] = useState("loading");
  useEffect(() => {
    findGames();
    setGame(false);
    setCategories([]);
    return () => {
      listOfGames = [];
      listOfCategories = [];
    };
  }, []);

  const findGames = () => {
    listOfGames = [];
    reduxState.data.map((datum) => {
      if (
        !listOfGames.includes(datum.displayGameType) &&
        datum.displayGameType !== "Story Book" &&
        datum.displayGameType !== "Fill In The Blank" &&
        // These games can be added back in later but don't fit the current mold
        datum.displayGameType !== "Solve It" &&
        datum.displayGameType !== "Crocodile" &&
        datum.displayGameType !== "FilterData" &&
        datum.displayGameType !== "Verb Activity"
      ) {
        listOfGames.push(datum.displayGameType);
      }
    });
    setDisplay("games");
  };
  const findCategories = (game) => {
    setGame(game);
    setDisplay("categories");
    listOfCategories = [];
    reduxState.data.map((datum) => {
      if (datum.displayGameType === game) {
        if (
          !listOfCategories.includes(datum.category) &&
          datum.category !== "Math" &&
          datum.category !== "Numbers and Age"
        ) {
          listOfCategories.push(datum.category);
        }
      }
    });
  };
  const updateCategories = (newCat) => {
    let newCategories = categories;
    if (newCategories.includes(newCat)) {
      newCategories = newCategories.filter((datum) => datum !== newCat);
    } else {
      newCategories.push(newCat);
    }
    setCategories([...newCategories]);
    dispatch(setEndlessCategories(newCategories));
  };
  const resetSelections = () => {
    setGame(false);
    setCategories([]);
    setDisplay("games");
  };
  let tabHeight = document.getElementsByClassName("topBar-tab")[0];
  if (tabHeight !== undefined) {
    tabHeight = parseInt(tabHeight.style.height, 10) + 5;
  } else {
    tabHeight = 15;
  }
  if (tabHeight > 45) {
    tabHeight = 45;
  }
  if (display === "loading") {
    return <LoadingScreen />;
  } else if (display === "games") {
    return (
      <GameSelect
        findCategories={findCategories}
        games={listOfGames}
        levelData={props.levelData}
      />
    );
  } else if (display === "categories") {
    return (
      <CategorySelect
        categories={listOfCategories}
        chosenCategories={categories}
        game={game}
        levelData={props.levelData}
        resetCategories={() => setCategories([])}
        setDisplay={(display) => setDisplay(display)}
        updateCategories={updateCategories}
      />
    );
  } else if (display === "difficulty") {
    return (
      <DifficultySelect
        categories={categories}
        game={game}
        resetSelections={resetSelections}
      />
    );
  }
};

export default EndlessModeMenu;

EndlessModeMenu.propTypes = {
  levelData: PropTypes.array,
};
