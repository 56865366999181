import { useEffect } from "react";
import { useRoutes, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import ReactGA from "react-ga4";

import Welcome from "../Welcome";
import UnitMenu from "../UnitMenuNEW";
// import SubUnitMenu from "../UnitSubMenu";
import GameTypeMenu from "../GameTypeMenu";
import StoryGameTypeMenu from "../StoryMenuScreens/GameTypeMenu";
import StorySubUnitMenu from "../StoryMenuScreens/SubUnitMenu";
import StoryUnitMenu from "../StoryUnitMenu";
import FullGameContainer from "../FullGameContainer";
import Dictionary from "../Dictionary";
import RedirectPage from "../Redirect";
import HistoryTimeline from "../HistoryTimeline/HistoryTimeline";
import Credits from "../Credits";
import EndlessModeMenu from "../EndlessModeMenu";
import ChooseStoryOrNormal from "../ChoosePath";
// import HistoryTimeline from "../HistoryTimeline";
import { getSearchParams } from "../nonUIFuncs";

// TODO: redirect if a url parameter does not match the data

const AppRouter = ({
  updateLevelData,
  scoreData,
  updateScoreData,
  setShowTopNav,
  dataset,
  config,
  levelData,
}) => {
  const location = useLocation();
  const params = getSearchParams(location);
  useEffect(() => {
    let paramsToSend = {};
    params.forEach((d) => {
      paramsToSend[d.id] = d.value
    });
    ReactGA.send({ hitType: 'pageview', page: location.pathname, params: JSON.stringify(paramsToSend) });
  }, [location.pathname]);
  let routesArr = useRoutes([
    {
      path: "/",
      element: <Welcome config={config} />,
    },
    {
      path: "/choose-path/",
      element: <ChooseStoryOrNormal config={config} />,
    },
    {
      path: "/credits/",
      element: <Credits config={config} />,
    },
    {
      path: "/dictionary/",
      element: <Dictionary config={config} />,
    },
    {
      path: "/timeline/",
      element: <HistoryTimeline config={config} />,
    },
    {
      path: "/choose-unit/",
      element: <UnitMenu scoreData={scoreData} config={config} />,
    },
    {
      path: "/choose-story-unit/",
      element: <StoryUnitMenu scoreData={scoreData} config={config} />,
    },
    {
      path: "/endless-mode/start-game/",
      element: (
        <FullGameContainer
          setShowTopNav={setShowTopNav}
          updateLevelData={updateLevelData}
          updateScoreData={updateScoreData}
          scoreData={scoreData}
          dataset={dataset}
          config={config}
        />
      ),
    },
    {
      path: "/endless-mode/",
      element: (
        <EndlessModeMenu
          scoreData={scoreData}
          levelData={levelData}
          config={config}
        />
      ),
    },
    {
      path: "/choose-game-type/",
      element: <GameTypeMenu scoreData={scoreData} config={config} />,
    },
    {
      path: "/story-choose-game-type/",
      element: <StoryGameTypeMenu scoreData={scoreData} config={config} />,
    },
    {
      path: "/choose-sub-unit/",
      element: <StorySubUnitMenu scoreData={scoreData} dataset={dataset} />,
    },
    {
      path: "/start-game/",
      element: (
        <FullGameContainer
          setShowTopNav={setShowTopNav}
          updateLevelData={updateLevelData}
          updateScoreData={updateScoreData}
          scoreData={scoreData}
          dataset={dataset}
          config={config}
        />
      ),
    },
    {
      path: "*",
      element: <RedirectPage />,
    },
  ]);
  return routesArr;
};

export default AppRouter;

AppRouter.propTypes = {
  updateLevelData: PropTypes.func,
  scoreData: PropTypes.array,
  updateScoreData: PropTypes.func,
  setShowTopNav: PropTypes.func,
  dataset: PropTypes.string,
};