import "./App.scss";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import ReactGA from 'react-ga4';
import { FontAwesomeIcon as FAIcon } from "@fortawesome/react-fontawesome";

import { setWidth, setHeight, selectReduxSlice, saveScoreData } from "./Store/store";

import AppRouter from "./Components/AppRouter";
import TopNavigation from "./Components/UI/TopNavigation";
import BottomNavigation from "./Components/UI/BottomNavigation";
import ClearDataPopup from "./Components/UI/ClearDataPopup";
import { faMessage } from "@fortawesome/free-solid-svg-icons";


import dataTest from "./AppDataTest";

let scoreDataLocal = [];
let levelDataLocal = [];

// Redlake Initilize
// ReactGA.initialize("G-77K5NRYVXE");

// Fort Belknap Initilize
// ReactGA.initialize("G-1E00CRMCD2");

// Salish Initialize
ReactGA.initialize("G-ZJYGHY6NP6");

// TODO: Add Pokagon

const App = ({scoreDataProp, levelDataProp, saveData, config}) => {
  const location = useLocation();
  const scoreDataRef = useRef([]);
  const reduxState = useSelector(selectReduxSlice);
  const reduxStateRef = useRef(reduxState);
  const datasetRef = useRef('');
  const dispatch = useDispatch();
  const [scoreData, setScoreData] = useState([]);
  const [levelData, setLevelData] = useState([]);
  const [showTopNav, setShowTopNav] = useState(true);
  const [ showClearData, setShowClearData ] = useState(false);
  const [fullStarScores, setFullStarScores] = useState([
    {difficulty: 1, currentScore: 0, totalScore: 0},
    {difficulty: 2, currentScore: 0, totalScore: 0},
    {difficulty: 3, currentScore: 0, totalScore: 0},
    {difficulty: 4, currentScore: 0, totalScore: 0},
    {difficulty: 5, currentScore: 0, totalScore: 0}
  ]);
  const [dataset, setDataset] = useState('');

  scoreDataRef.current = scoreData;
  datasetRef.current = dataset;
  reduxStateRef.current = reduxState;

  useEffect(() => {
    const handleResize = () => {
      dispatch(setWidth(window.innerWidth));
      dispatch(setHeight(window.innerHeight));
    };
    window.addEventListener("resize", handleResize);
    if (scoreDataProp.length > 0) {
      let scoreDataToSet = filterScoreData(scoreDataProp);
      dispatch(saveScoreData(scoreDataToSet));
      setScoreData(scoreDataToSet);
      scoreDataDisplayUpdate(scoreDataToSet);
    }
    if (levelDataProp.length > 2) {
      setLevelData(levelDataProp);
    }
    // findSetIDs(); // comment/uncomment when error checking data
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const filterScoreData = (data) => {
    let keys = [];
    let filtered = [];
    data.forEach((d) => {
      if (!keys.includes(d.Key)) {
        keys.push(d.Key);
        filtered.push(d);
      }
    });
    return filtered;
  };
  const updateScoreData = (newData) => {
    scoreDataLocal = scoreData;
    let newScoreData = scoreDataLocal.map((game) => {
      if (newData.Key === game.Key) {
        let gameScore = game.difficultyScores[newData.difficulty];
        if (
          (newData.firstStars + newData.secondStars)
            >= (gameScore.firstStars + gameScore.secondStars)
          && newData.score >= gameScore.score
        ) {
          return {
            ...game,
            difficultyScores: {
              ...game.difficultyScores,
              [newData.difficulty]: {
                ...game.difficultyScores[newData.difficulty],
                correct: newData.correct,
                firstNextGoals: newData.firstNextGoals,
                firstStars: newData.firstStars,
                incorrect: newData.incorrect,
                score: newData.score,
                secondNextGoals: newData.secondNextGoals,
                secondStars: newData.secondStars,
                stars: newData.stars,
                time: newData.time,
              },
            },
          }
        } else {
          return game;
        }
      } else {
        return game;
      }
    });
    setScoreData(newScoreData);
    scoreDataDisplayUpdate(newScoreData);
    dispatch(saveScoreData(newScoreData));
  };
  const updateLevelData = (data) => {
    levelDataLocal = levelData;
    let categoryLength = data.category.length;
    let newLevelData = levelDataLocal.map((game) => {
      let gameCategory = game.category;
      if (typeof gameCategory !== "string") {
        gameCategory = game.category[0];
      }
      if (
        (game.gameType === `${categoryLength} Categories` && data.spreadGameType === game.spreadGameType) // Multiple Categories
        || (data.gameType === game.gameType && data.category[0] === gameCategory) // Solo Categories
      ) {
        return {
          ...game,
          category: data.category,
          levelEasy:
            data.difficulty === "easy" && data.newLevel > game.levelEasy
              ? data.newLevel
              : game.levelEasy,
          categoryEasy:
            data.difficulty === "easy" && data.newLevel > game.levelEasy
              ? data.category
              : game.categoryEasy,
          levelMedium:
            data.difficulty === "medium" && data.newLevel > game.levelMedium
              ? data.newLevel
              : game.levelMedium,
          categoryMedium:
            data.difficulty === "medium" && data.newLevel > game.levelMedium
              ? data.category
              : game.categoryMedium,
          levelHard:
            data.difficulty === "hard" && data.newLevel > game.levelHard
              ? data.newLevel
              : game.levelHard,
          categoryHard:
            data.difficulty === "hard" && data.newLevel > game.levelHard
              ? data.category
              : game.categoryHard,
        };
      } else {
        return game;
      }
    });
    setLevelData(newLevelData);
  };
  const clearStorage = () => {
    saveData([], []);
    // localStorage.clear();
    window.location.reload();
  };
  const scoreDataDisplayUpdate = (currentScoreData) => {
    let settingFullStarScores = [
      { difficulty: 1, currentScore: 0, totalScore: 0 },
      { difficulty: 2, currentScore: 0, totalScore: 0 },
      { difficulty: 3, currentScore: 0, totalScore: 0 },
      { difficulty: 4, currentScore: 0, totalScore: 0 },
      { difficulty: 5, currentScore: 0, totalScore: 0 },
    ];
    currentScoreData.forEach((d) => {
      let easyDiffNum = d.difficultyScores.easy.difficulty;
      settingFullStarScores[easyDiffNum - 1].totalScore =
        settingFullStarScores[easyDiffNum - 1].totalScore + 3;
      settingFullStarScores[easyDiffNum - 1].currentScore =
        settingFullStarScores[easyDiffNum - 1].currentScore +
        d.difficultyScores.easy.stars;
      let medDiffNum = d.difficultyScores.medium.difficulty;
      settingFullStarScores[medDiffNum - 1].totalScore =
        settingFullStarScores[medDiffNum - 1].totalScore + 3;
      settingFullStarScores[medDiffNum - 1].currentScore =
        settingFullStarScores[medDiffNum - 1].currentScore +
        d.difficultyScores.medium.stars;
      let hardDiffNum = d.difficultyScores.hard.difficulty;
      settingFullStarScores[hardDiffNum - 1].totalScore =
        settingFullStarScores[hardDiffNum - 1].totalScore + 3;
      settingFullStarScores[hardDiffNum - 1].currentScore =
        settingFullStarScores[hardDiffNum - 1].currentScore +
        d.difficultyScores.hard.stars;
      }
    );
    setFullStarScores(settingFullStarScores);
  };
  window.onbeforeunload =  saveData(scoreDataRef.current, levelData);


  return (
    <div className="center">
      <div className="centerContentContainer">
        <AppRouter
          levelData={levelData}
          updateLevelData={updateLevelData}
          scoreData={scoreDataRef.current}
          updateScoreData={updateScoreData}
          setShowTopNav={setShowTopNav}
          dataset={datasetRef.current}
          config={config}
        />
      </div>
      <TopNavigation
        setShowClearData={setShowClearData}
        showClearData={showClearData}
        showNav={showTopNav}
      />
      <BottomNavigation fullStarScores={fullStarScores} config={config} />
      {showClearData ? (
        <ClearDataPopup
          clearData={clearStorage}
          setShowClearData={setShowClearData}
        />
      ) : null}
      {config.FeedbackLink !== undefined && config.FeedbackLink.length > 0 ? (
        <div className="feedback_button_container">
          <a
            href="https://forms.gle/NJd1pfbuV8Dv3nrs8"
            className="feedback_button">
            <div className="feedback_text">Tell us what you think!</div>
            <FAIcon icon={faMessage} size="xl" />
          </a>
        </div>
      ) : null}
      <div style={{position: 'absolute', bottom: 0, left: 2, zIndex: 99999, fontSize: 12}}>v {config.VersionNumber}</div>
    </div>
  );
}

export default App;

App.propTypes = {
  scoreDataProp: PropTypes.array,
  levelDataProp: PropTypes.array,
  saveData: PropTypes.func,
  config: PropTypes.object,
};