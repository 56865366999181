import { createSlice } from "@reduxjs/toolkit";
import {
  getSearchParams,
  shuffle,
  findGameFromConfigAndParams,
} from "../Components/nonUIFuncs";

import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "@firebase/firestore";
import PlayerLevelData from "../data/playerLevel.json";
// import { FirebaseConfig, NumberDataCategory, ParamsToFindGame } from "../config/RedLake/config";
import { FirebaseConfig, NumberDataCategory, ParamsToFindGame } from "../config/Salish/config";
// import { FirebaseConfig, NumberDataCategory, ParamsToFindGame } from "../config/FortBelknap/config";
// import { FirebaseConfig, NumberDataCategory, ParamsToFindGame } from "../config/Pokagon/config";

const firebaseConfig = FirebaseConfig;

window.app = initializeApp(firebaseConfig);
const db = getFirestore(window.app);
db._settings.experimentalAutoDetectLongPolling = true;

export const reduxSlice = createSlice({
  name: "reduxSlice",
  initialState: {
    value: {
      scoreData: [],
      currentLevel: 0,
      currentLevelProgress: 0,
      width: window.innerWidth,
      height: window.innerHeight,
      stats: {
        hits: 0,
        misses: 0,
        score: 0,
        roundStats: {
          hits: 0,
          misses: 0,
          score: 0,
        },
      },
      firebaseSheetsPulled: [],
      location: "",
      homeDifficulty: "",
      data: [],
      gameData: [],
      numberData: [],
      sortedData: [],
      gameDescriptions: [],
      goldStar: [],
      popupWords: [],
      uiWords: [],
      displayCanvas: false,
      counter: 0,
      difficulty: "easy",
      specificGame: {
        displayGameType: "",
        game: "",
        Key: 0,
        category: "",
      },
      screenDisplay: "",
      bottomBarStarValues: {
        currentStars: 0,
        totalStars: 1,
      },
      playerLevelNumbers: {
        playerLevel: 0,
        allTotalStars: 1,
        currentStars: 0,
      },
    },
  },
  reducers: {
    setScoreData: (state, action) => {
      state.value = {
        ...state.value,
        scoreData: action.payload
      }
    },
    getDifficulty: (state, action) => {
      state.value = { ...state.value, difficulty: action.payload };
    },
    getEndlessLevel: (state, action) => {
      state.value = { ...state.value, currentLevel: action.payload };
    },
    getEndlessLevelProgress: (state, action) => {
      state.value = { ...state.value, currentLevelProgress: action.payload };
    },
    getSpecificGame: (state, action) => {
      state.value = { ...state.value, specificGame: action.payload };
    },
    getSortedData: (state, action) => {
      state.value = { ...state.value, sortedData: action.payload };
    },
    setScreenDisplay: (state, action) => {
      state.value = { ...state.value, screenDisplay: action.payload };
    },
    getStats: (state, action) => {
      state.value = {
        ...state.value,
        stats: {
          ...state.value.stats,
          ...action.payload,
          roundStats: {
            ...state.value.stats.roundStats,
            ...action.payload.roundStats,
          },
        },
      };
    },
    getWidth: (state, action) => {
      state.value = { ...state.value, width: action.payload };
    },
    getHeight: (state, action) => {
      state.value = { ...state.value, height: action.payload };
    },
    setPlayerLevel: (state, action) => {
      state.value = {
        ...state.value,
        playerLevelNumbers: action.payload,
      };
    },
    setFirestoreData: (state, action) => {
      let firebaseSheets = [...state.value.firebaseSheetsPulled];
      if (!firebaseSheets.includes(action.payload.nameToStore)) {
        firebaseSheets.push(action.payload.nameToStore);
      }
      state.value = {
        ...state.value,
        [action.payload.nameToStore]: action.payload.sortedData,
        firebaseSheetsPulled: firebaseSheets,
      };
    },
    getEndlessCategories: (state, action) => {
      state.value = { ...state.value, endlessCategories: action.payload };
    },
    setBottomPercentageValues: (state, action) => {
      state.value = {
        ...state.value,
        bottomBarStarValues: {
          currentStars: action.payload.currentStars,
          totalStars: action.payload.totalStars,
        },
      };
    },
  },
});

export const {
  getDifficulty,
  getSpecificGame,
  getSortedData,
  setScreenDisplay,
  getStats,
  getWidth,
  getHeight,
  setFirestoreData,
  setBottomPercentageValues,
  setPlayerLevel,
  getEndlessCategories,
  getEndlessLevel,
  getEndlessLevelProgress,
  setScoreData,

} = reduxSlice.actions;

export const setDifficulty = (difficulty) => async (dispatch) => {
  dispatch(getDifficulty(difficulty));
};
export const setEndlessCategories = (categories) => async (dispatch) => {
  dispatch(getEndlessCategories(categories));
};
export const setEndlessLevel = (level) => async (dispatch) => {
  dispatch(getEndlessLevel(level));
};
export const setEndlessLevelProgress = (levelProgress) => async (dispatch) => {
  dispatch(getEndlessLevelProgress(levelProgress));
};
export const setInitialGameData = (
  loc,
  data,
  gameData,
  gameType,
  singleGameData,
  isEndless,
  selectedEndlessGame,
) => async (dispatch) => {
  let difficulty = null;
  let specificGame = {};
  let newFilteredData = [];
  let locationArr = getSearchParams(loc);
  if (isEndless) {
    specificGame = selectedEndlessGame;
    let englishArray = [];
    newFilteredData = [];
    singleGameData.forEach((d) => {
      if (!englishArray.includes(d.English)) {
        englishArray.push(d.English);
        newFilteredData.push({
          ...d,
          Key: d.homeName === undefined ? `${d.Key}Story` : d.Key,
          homeName: d.homeName || d.storyGame,
          subCat: d.subCat || d.group,
        });
      }
    });
  } else {
    difficulty = "easy";
    // specificGame = data.find(
    //   (game) =>
    //     game.category === locationArr.find((d) => d.id === "unit").value &&
    //     game.target === locationArr.find((d) => d.id === "game-name").value &&
    //     game.displayGameType === locationArr.find((d) => d.id === "game-type").value
    // );
    console.log(singleGameData);
    let filteredGames = [];
    let KeyParam = locationArr.find((k) => k.id === "Key");
    if (KeyParam !== undefined && KeyParam.value.length > 0) {
      filteredGames = data.filter((d) => Number(d.Key) === Number(KeyParam.value));
    } else {
      ParamsToFindGame.forEach((p) => {
        let arrayToFilter = filteredGames.length > 0 ? filteredGames : data;
        let param = locationArr.find((d) => d.id === p.param);
        let value = param.value;
        let dataToCheck = p.data;
        if (param.id === "game-type") {
          value = value.replaceAll(" ", "").replaceAll("-", "");
          dataToCheck = "game";
        }
        if (dataToCheck === "target" && value === "Story") {
          // TODO: Skip target param in story data
        } else {
          arrayToFilter = arrayToFilter.filter(
            (d) => d[dataToCheck] === value
          );
        }
        filteredGames = arrayToFilter;
      });
    }

    specificGame = filteredGames[0];
    newFilteredData = singleGameData;
  }
  dispatch(getDifficulty(difficulty));
  dispatch(getSpecificGame(specificGame));
  dispatch(getSortedData(newFilteredData));
  dispatch(setScreenDisplay("startScreen"));
};

export const findPlayerLevel = (
  newStarNum,
  currentPlayerLevelNumbersState,
) => async (dispatch) => {
  // Passing only the difference between last and new number of stars for the
  // current game and difficulty. The player can never lose stars, only gain.
  let totalStars = currentPlayerLevelNumbersState.allTotalStars;
  let currentStars = currentPlayerLevelNumbersState.currentStars + newStarNum;
  let playerLevel = 0;
  let percentage = (currentStars / totalStars) * 100;
  for (let i = 0; i <= PlayerLevelData.length; i++) {
    let d = PlayerLevelData[i];
    if (d.Progress <= percentage) {
      playerLevel = d.Level;
    } else {
      break;
    }
  }
  dispatch(
    setPlayerLevel({
      playerLevel: playerLevel,
      allTotalStars: totalStars,
      currentStars: currentStars,
    })
  );
};

export const findInitialPlayerLevelOnLoad = (
  currentStars,
  totalStars,
) => async (dispatch) => {
  // Based on currentStars and totalStars, find the player level from
  // PlayerLevelData and set state
  let playerLevel = 0;
  let percentage = (currentStars / totalStars) * 100;
  for (let i = 0; i <= PlayerLevelData.length; i++) {
    let d = PlayerLevelData[i];
    if (d.Progress <= percentage) {
      playerLevel = d.Level;
    } else {
      break;
    }
  }
  dispatch(
    setPlayerLevel({
      playerLevel: playerLevel,
      allTotalStars: totalStars,
      currentStars: currentStars,
    })
  );
};
export const setStats = (stats) => async (dispatch) => {
  // Expected data format: { hits: 4, misses: 1, score: 3, time: 15 }
  dispatch(getStats(stats));
};
export const clearStats = () => async (dispatch) => {
  dispatch(
    getStats({
      hits: 0,
      misses: 0,
      score: 0,
      roundStats: {
        hits: 0,
        misses: 0,
        score: 0,
      },
    })
  );
};
export const setWidth = (width) => (dispatch) => {
  dispatch(getWidth(width));
};
export const setHeight = (height) => (dispatch) => {
  dispatch(getHeight(height));
};
export const getData = (collectionName, nameToStore) => async (dispatch) => {
  const docSnap = await getDocs(collection(db, collectionName));
  const docs = docSnap.docs.map((doc, idx) => {
    let newDoc = {
      ...doc.data(),
      firebaseID: doc.id,
      Key: doc.data().Key !== undefined ? doc.data().Key : idx,
    };
    return newDoc;
  });
  let filteredData = [];
  let idArray = [];
  docs.forEach((datum) => {
    if (!idArray.includes(datum.Key) && isNaN(datum.Key) === false) {
      filteredData.push(datum);
      idArray.push(datum.Key);
    }
  });
  let sortedData = filteredData.sort((a, b) => a.Key - b.Key);
  let cleanedData = [...sortedData];
  if (nameToStore === "gameData") {
    let cat = NumberDataCategory === undefined ? "Numbers 1" : NumberDataCategory;
    let numberData = cleanedData.filter(
      (d) => d.subCat === cat //TODO: when there is data, change category to numberdata for Fort Belknap
    );
    dispatch(
      setFirestoreData({
        sortedData: numberData,
        nameToStore: "numberData",
      })
    );
  }
  dispatch(
    setFirestoreData({ sortedData: cleanedData, nameToStore: nameToStore })
  );
};
export const getBottomPercentageValues = (
  currentStars,
  totalStars,
) => async (dispatch) => {
    dispatch(
      setBottomPercentageValues({
        currentStars: currentStars,
        totalStars: totalStars,
      })
    );
};

export const saveScoreData = (scoreData) => async (dispatch) => {
  dispatch(setScoreData(scoreData));
}

export default reduxSlice.reducer;

export const selectReduxSlice = (state) => state.store.value;