import "./index.scss";
import "../../App.scss";
import { useEffect, useState, useRef } from "react";
import {
  fetchImage,
  fetchAudio,
  redirectFromParams,
  compare,
  useLocationParams,
  getSearchParams,
} from "../nonUIFuncs";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectReduxSlice, getBottomPercentageValues } from "../../Store/store";
import PropTypes from "prop-types";

const Unit = ({ image, unitName, Key, displayStars, selectUnit, hideButton = false }) => {
  return (
    <div className="unit_container">
      <div className="unit_background" />
      <div className="unit_header">
        <h4 className="unit_headertext">{unitName}</h4>
      </div>
      <div className="unit_contents">
        <div className="unit_centercontents">
          <div
            className="unit_image"
            style={{ backgroundImage: `url(${fetchImage(image)})` }}>
            {!hideButton ? (
              <div
                onClick={selectUnit}
                className="unit_playbutton hovercursor">
                <p className="unit_playbuttontext">View</p>
              </div>
            ) : null}
          </div>
        </div>
        <div className={`unit_stars_${displayStars}`} />
      </div>
    </div>
  );
};









const SmallDisplaySubUnits = ({
  Key,
  currentStars,
  displayStars,
  image,
  name,
  totalStars,
  unitName,
  subUnits,
  subUnitData,
  setSubUnitData,
}) => {
  return (
    <div className="smalldisplay_subunits_container">
      <div style={{marginBottom: 10}}>
        <Unit
          hideButton={true}
          Key={Key}
          currentStars={currentStars}
          displayStars={displayStars}
          image={image}
          name={name}
          totalStars={totalStars}
          unitName={unitName}
        />
      </div>
      <div className="all_subunit_container">
        {/* <div className="smalldisplay_subunits_buttoncontainer"> */}
        {subUnits.map((d) => {
          return (
            <SubUnitButton
              key={d.subUnit}
              data={d}
              setSubUnitData={setSubUnitData}
            />
          );
        })}
      </div>
      <div className="subunit_vocab_bank">
        {subUnitData.map((d) => {
          let fontSize = 130 / d.Language.length;
          if (fontSize > 26) {
            fontSize = 26;
          } else if (fontSize < 10) {
            fontSize = 10;
          }
          let audioFile = new Audio(fetchAudio(d.App_Audio));
          return (
            <div
              key={d.Language}
              className="subunit_vocab_smalldisplay"
              onClick={() => audioFile.play()}
            >
              <div
                style={{
                  backgroundImage: `url(${fetchImage(d.App_Art)})`,
                }}
                className="subunit_vocab_image_smalldisplay"
              />
              <div
                className="subunit_vocab_text"
                style={{ fontSize: fontSize, lineHeight: 0.8 }}
              >
                {d.Language}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const UnitDisplaySmallScreen = ({ units, selectUnit, subUnits, subUnitData, setSubUnitData }) => {
  let currentUnitData = {};
  const currentUnitRef = useRef(1);
  const [currentUnit, setCurrentUnit] = useState(1);
  currentUnitRef.current = currentUnit;
  useEffect(() => {
    // console.log(units);
    selectUnit(
      units.find((d) => d.Key === currentUnitRef.current) || {
        image: "",
        unitName: "",
        Key: 1,
        displayStars: 0,
      }
    );
  }, [units]);

  const backwardUnit = () => {
    setSubUnitData([]);
    let newUnit = 1;
    if (currentUnitRef.current === 1) {
      newUnit = units.length;
      setCurrentUnit(units.length);
    } else {
      newUnit = currentUnitRef.current - 1;
      setCurrentUnit(currentUnitRef.current - 1);
    }
    selectUnit(
      units.find((d) => d.Key === newUnit) || {
        image: "",
        unitName: "",
        Key: 1,
        displayStars: 0,
      }
    );
  };


  const forwardUnit = () => {
    setSubUnitData([]);
    let newUnit = 1;
    if (currentUnitRef.current === units.length) {
      setCurrentUnit(1);
    } else {
      newUnit = currentUnitRef.current + 1;
      setCurrentUnit(currentUnitRef.current + 1);
    }
    selectUnit(
      units.find((d) => d.Key === newUnit) || {
        image: "",
        unitName: "",
        Key: 1,
        displayStars: 0,
      }
    );
  };
  currentUnitData = units.find((d) => d.Key === currentUnitRef.current) || {
    image: "",
    unitName: "",
    Key: 1,
    displayStars: 0,
  };
  return (
    <div className="unitmenu_smallscreencontainer">
      <div onClick={backwardUnit} className="unitmenu_backwardunit" />
      <SmallDisplaySubUnits
        {...currentUnitData}
        setSubUnitData={setSubUnitData}
        subUnits={subUnits}
        subUnitData={subUnitData}
      />
      <div onClick={forwardUnit} className="unitmenu_forwardunit" />
    </div>
  );
};
const SubUnitButton = ({data, setSubUnitData}) => {
  let fontSize = 210 / data.subUnit.length;
  if (fontSize > 35) {
    fontSize = 35;
  } else if (fontSize < 12) {
    fontSize = 12;
  }
  return (
    <Link
      to={{
        pathname: "/choose-game-type",
        search: useLocationParams(`unit=${data.unit.Key + data.unit.unitName}&game-name=${data.subUnit}`),
      }}
      className="subunit_container"
      onMouseOver={() => {
        setSubUnitData(data.data);
      }}
      style={{ color: "white" }}>
      <div style={{ fontSize: fontSize }} className="subunit_container_text">
        {data.subUnit}
      </div>
    </Link>
  );
};
const UnitMenu = ({ scoreData, config }) => {
  const reduxState = useSelector(selectReduxSlice);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  let difficultyFromLocation = Number(
    getSearchParams(location).find((param) => param.id === "difficulty").value
  );
  const unitsRef = useRef([]);
  const reduxStateRef = useRef(reduxState);
  const [units, setUnits] = useState([]);
  const [subUnits, setSubUnits] = useState([]);
  const [subUnitData, setSubUnitData] = useState([]);
  reduxStateRef.current = reduxState;
  unitsRef.current = units;

  useEffect(() => {
    document.title = `${config.AppName} - Units`;
    let redirectPath = redirectFromParams(["difficulty"]) || "";
    if (redirectPath.length > 0) {
      navigate(redirectPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    findDifficultyGames(
      reduxStateRef.current.data,
      reduxStateRef.current.gameData
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scoreData]);

  let unitArr = [];
  let unitNumArr = [];
  const findDifficultyGames = (data, gameData) => {
    let games = [];
    scoreData.forEach((d) => {
      if (d.difficultyScores.easy.difficulty === difficultyFromLocation) {
        games.push({
          ...d.difficultyScores.easy,
          category: d.category,
          gameName: d.gameName,
          gameType: d.gameType,
          key: `${d.category}${d.gameName}Easy`,
        });
      } else if (
        d.difficultyScores.medium.difficulty === difficultyFromLocation
      ) {
        games.push({
          ...d.difficultyScores.medium,
          category: d.category,
          gameName: d.gameName,
          gameType: d.gameType,
          key: `${d.category}${d.gameName}Medium`,
        });
      } else if (
        d.difficultyScores.hard.difficulty === difficultyFromLocation
      ) {
        games.push({
          ...d.difficultyScores.hard,
          category: d.category,
          gameName: d.gameName,
          gameType: d.gameType,
          key: `${d.category}${d.gameName}Hard`,
        });
      }
    });
    // setDifficultyGames(games);
    findUnits(games, data, gameData);
  };
  const findUnits = (games, data, gameData) => {
    gameData.forEach((datum) => {
      if (
        !unitNumArr.includes(datum.lesson) &&
        typeof Number(datum.lesson) === "number"
      ) {
        unitNumArr.push(datum.lesson);
        unitArr.push({
          Key: Number(datum.lesson),
          name: `Unit ${datum.lesson}`,
          unitName: datum.homeName,
          image: `Category_Selection_${datum.lesson}1.png`,
          currentStars: 0,
          totalStars: 0,
          displayStars: 0,
        });
      }
      unitArr.sort((a, b) => compare(a.Key, b.Key));
    });
    findStars(unitArr, games, data);
  };
  const findStars = (unitArr, games, data) => {
    let testTotal = 0;
    let testCurrent = 0;
    let adjustedUnits = unitArr.map((unit) => {
      let currentStars = 0;
      let totalStars = 0;
      let displayStars = 0;
      games.forEach((d) => {
        if (d.category === unit.unitName) {
          currentStars = currentStars + d.stars;
          let goldStar = reduxStateRef.current.goldStar.find(
            (g) => g.game === d.gameType
          );
          if (goldStar?.secondType !== "none") {
            totalStars = totalStars + 6;
          } else {
            totalStars = totalStars + 3;
          }
        }
      });
      testTotal = testTotal + totalStars;
      testCurrent = testCurrent + currentStars;
      let percent = (currentStars / totalStars) * 100;
      if (percent >= 99) {
        displayStars = 3;
      } else if (percent >= 50 && percent < 99) {
        displayStars = 2;
      } else if (percent >= 1 && percent < 50) {
        displayStars = 1;
      } else {
        displayStars = 0;
      }
      return {
        ...unit,
        totalStars: totalStars,
        currentStars: currentStars,
        displayStars: displayStars,
      };
    });
    let uniqueCategories = [];
    adjustedUnits = adjustedUnits.filter((d) => {
      if (!uniqueCategories.includes(d.unitName)) {
        uniqueCategories.push(d.unitName);
        return d;
      }
    });
    let categoriesWGames = [];
    data.forEach((d) => {
      if (!categoriesWGames.includes(d.category.toUpperCase())) {
        categoriesWGames.push(d.category.toUpperCase());
      }
    });
    let filteredUnits = adjustedUnits.filter((d) =>
      categoriesWGames.includes(d.unitName.toUpperCase())
    );
    dispatch(getBottomPercentageValues(testCurrent, testTotal));
    setUnits(filteredUnits);
  };
  const selectUnit = (unit) => {
    let unitData = [];
    let data = reduxStateRef.current.gameData.filter((d) => d.homeName === unit.unitName);
    let subUnits = [];
    data.forEach((d) => {
      if (!subUnits.includes(d.subCat)) {
        subUnits.push(d.subCat);
      }
    });
    subUnits.forEach((d) => {
      let uData = data.filter((datum) => d === datum.subCat);
      unitData.push({unit: unit, subUnit: d, data: uData});
    });
    setSubUnits(unitData);
  };
  return (
    <div className="all_pages_container unitmenu_container">
      <div className="unitmenu_bannercontainer">
        <div className="unitmenu_banner">
          <h1>Pick A Unit!</h1>
        </div>
        <div className="unitmenu_guidecharacter" />
      </div>
      {reduxStateRef.current.width > 775 ? (
        <div className="unitmenu_contentscontainer">
          <div className="unitmenu_contents">
            <div className="unitmenu_left_contents">
              {unitsRef.current.map((unit) => {
                return (
                  <Unit
                    selectUnit={() => {
                      selectUnit(unit);
                      setSubUnitData([]);
                    }}
                    key={unit.name}
                    {...unit}
                  />
                );
              })}
            </div>
            <div className="unitmenu_right_contents">
              <div style={{ width: "100%", padding: 5 }}>
                <div className="all_subunit_container">
                  {subUnits.map((d) => {
                    return (
                      <SubUnitButton
                        key={d.subUnit}
                        data={d}
                        setSubUnitData={setSubUnitData}
                      />
                    );
                  })}
                </div>
                <div className="subunit_vocab_bank">
                  {subUnitData.map((d) => {
                    let fontSize = 140 / d.Language.length;
                    if (fontSize > 26) {
                      fontSize = 26;
                    } else if (fontSize < 10) {
                      fontSize = 10;
                    }
                    let audioFile = new Audio(fetchAudio(d.App_Audio));
                    return (
                      <div
                        key={d.Language}
                        className="subunit_vocab"
                        onClick={() => audioFile.play()}>
                        <div
                          style={{
                            backgroundImage: `url(${fetchImage(d.App_Art)})`,
                          }}
                          className="subunit_vocab_image"
                        />
                        <div
                          className="subunit_vocab_text"
                          style={{ fontSize: fontSize, lineHeight: 0.8 }}>
                          {d.Language}
                        </div>
                      </div>
                    );
                  })}
                </div>
                {subUnits.length === 0 ? (
                  <div
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      fontSize: 24,
                      margin: 15,
                    }}>
                    Please Select a unit to the Left
                  </div>
                ) : (
                  null
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <UnitDisplaySmallScreen
          selectUnit={selectUnit}
          subUnits={subUnits}
          setSubUnitData={setSubUnitData}
          subUnitData={subUnitData}
          units={unitsRef.current}
        />
      )}
    </div>
  );
};

export default UnitMenu;

Unit.propTypes = {
  image: PropTypes.string,
  unitName: PropTypes.string,
  Key: PropTypes.number,
  displayStars: PropTypes.number,
};

UnitDisplaySmallScreen.propTypes = {
  units: PropTypes.array,
};

UnitMenu.propTypes = {
  scoreData: PropTypes.array,
  config: PropTypes.object,
};
