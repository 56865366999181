import "./index.scss";
import "../../App.scss";
import { PropTypes } from "prop-types";
import { Link, useLocation } from "react-router-dom";

const ChooseStoryOrNormal = () => {
  const location = useLocation();
  return (
    <div className="choose_container all_pages_container">
      <div className="choose_button_container" style={{ left: 0 }}>
        <Link className="choose_button" to={{ pathname: "/choose-unit", search: location.search }}>
          <div className="choose_button_text">Original</div>
        </Link>
      </div>
      <div className="choose_button_container" style={{ right: 0 }}>
        <Link
          className="choose_button_right"
          to={{ pathname: "/choose-story-unit", search: location.search }}
        >
          <div className="choose_button_text">Story</div>
        </Link>
      </div>
    </div>
  );
};

export default ChooseStoryOrNormal;

ChooseStoryOrNormal.propTypes = {
  config: PropTypes.object,
};